.icon {
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin-left: 15px;
    border-radius: 5px;
}

.noodle {
    position: fixed;
    bottom: 0;
    width: 100vw;
}